import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({
  isOpen,
  confirm,
  reject,
  confirmLabel,
  rejectLabel,
  bodyText,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={reject}>
      {bodyText && (
        <ModalBody>
          {bodyText}
        </ModalBody>
      )}
      <ModalFooter>
        <Button
          className="button button-default"
          color="primary"
          onClick={confirm}
        >
          {confirmLabel}
        </Button>{' '}
        <Button
          className="button button-default-outlined"
          color="secondary"
          onClick={reject}>
          {rejectLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  confirmLabel: 'Accept',
  rejectLabel: 'Deny',
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  confirm: PropTypes.func,
  reject: PropTypes.func,
  confirmLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  bodyText: PropTypes.string,
};

export default ConfirmationModal;
