import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styles from './modalComponent.module.scss';

const ModalComponent = ({
  isModalOpen,
  toggleModal,
  modalHeaderTitle,
  isModalBody = true,
  isModalFooter = true,
  confirm = () => console.log('Confirm'),
  confirmLabel = 'Submit',
  reject = () => console.log('Reject'),
  rejectLabel = 'Cancel',
  customClass,
  children
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className={`${styles.modal}${customClass ? ` ${customClass}` : ''}`}
    >
      {modalHeaderTitle && (
        <ModalHeader
          toggle={toggleModal}
          className={styles.modalHeader}
        >
          {modalHeaderTitle}
        </ModalHeader>
      )}
      {isModalBody && (
        <ModalBody className={styles.modalBody}>
          {children}
        </ModalBody>
      )}
      {isModalFooter && (
        <ModalFooter className={styles.modalFooter}>
          {confirmLabel && (
            <Button
              className="button button-default"
              color="primary"
              onClick={confirm}
            >
              {confirmLabel}
            </Button>
          )}
          {rejectLabel && (
            <Button
              className="button button-default-outlined"
              color="secondary"
              onClick={reject}>
              {rejectLabel}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  )
}

ModalComponent.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  modalHeaderTitle: PropTypes.string,
  isModalBody: PropTypes.bool,
  isModalFooter: PropTypes.bool,
  confirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  reject: PropTypes.func,
  rejectLabel: PropTypes.string,
  children: PropTypes.node
}

export default ModalComponent