import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import apiClient from '../gears/apiClient'
import ComponentLoader from './ComponentLoader'

const FilePreview = ({id}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPreview = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get(`/knowledge-base/${id}`);
      setPreviewUrl(response.knowledgeBaseUrl)
    } catch(e) {
      toastr.error(e?.message)
    }
    setIsLoading(false);
  }
  
  useEffect(() => {
    if(id) {
      fetchPreview();
    }
  }, [])

  return (
    <div className="h-100">
      {isLoading && <ComponentLoader />}
      <iframe src={previewUrl} width="100%" height="100%" title="File preview" />
    </div>
  )
}

FilePreview.propTypes = {
  id: PropTypes.number
}

export default FilePreview