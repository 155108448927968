import { toastr } from "react-redux-toastr"
import apiClient from '../../gears/apiClient';

export const fetchAiKnowledge = async (setAiKnowledgeData, setIsLoading) => {
  setIsLoading(true);
  try {
    const response = await apiClient.get('/list-knowledge-base');
    setAiKnowledgeData(response.knowledge);
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}

export const deleteKnowledge = async (
  id,
  knowledgeData,
  setAiKnowledgeData,
  setIsLoading,
  onRejectButtonClick
) => {
  setIsLoading(true);
  try {
    await apiClient.delete(`/remove-knowledge-base/${id}`);
    setAiKnowledgeData(knowledgeData.filter(data => data.id !== id));
    onRejectButtonClick();
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}