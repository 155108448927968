import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from 'reactstrap';
import AiKnowledgeBaseTable from '../../components/tables/AiKnowledgeBaseTable/AiKnowledgeBaseTable';
import ModalComponent from '../../components/modal/ModalComponent';
import CreateNewEntryForm from '../../components/forms/CreateNewEntryForm';

const AiKnowledgeBase = () => {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [needToUpdate, setNeedToUpdate] = useState(true);
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>Ai Knowledge Base</h2>
					</div>
				</Col>
        <Col sm="6">
          <div className="page-actions d-flex flex-column align-items-end">
            <Button
              className="button button-default"
							onClick={() => setIsCreateModalOpen(true)}
            >
              Create
            </Button>
          </div>
        </Col>
			</Row>

			<div className="page-block page-block_border">
        <AiKnowledgeBaseTable
					needToUpdate={needToUpdate}
					setNeedToUpdate={setNeedToUpdate}
				/>
      </div>

			{isCreateModalOpen && (
				<ModalComponent
					isModalOpen={isCreateModalOpen}
					modalHeaderTitle="Create a new entry"
					toggleModal={() => setIsCreateModalOpen(!isCreateModalOpen)}
					isModalFooter={false}
				>
					<CreateNewEntryForm
						closeModal={() => setIsCreateModalOpen(false)}
						updateData={() => setNeedToUpdate(true)}
					/>
				</ModalComponent>
			)}
		</div>
	);
};

export default AiKnowledgeBase;
