import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, FormGroup } from 'reactstrap';
import ComponentLoader from '../ComponentLoader';
import Dropzone from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  file: Yup.mixed().when('hide', {
    is: false,
    then: Yup.mixed().required('A file is required'),
  }),
});

const CreateNewEntryForm = ({ entryData = null, closeModal, updateData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateOrCreateKnowledgeBase = async (data) => {
    setIsLoading(true);
    const request = entryData
      ? apiClient.put(`/knowledge-base/${entryData.id}`, {
        active: true,
        description: data.description,
      })
      : apiClient.post('/store-knowledge-base', data)

    try {
      await request;
      updateData();
      closeModal();
      toastr.success(`${entryData ? 'Updated' : 'Created'} successfully`)
    } catch(e) {
      toastr.error(e?.error);
    }

    setIsLoading(false);
  }

  return (
    <div className="deploy-google-admin">

      {isLoading && <ComponentLoader />}

      <Formik
        initialValues={{
          description: entryData?.description || '',
          file: null,
          hide: entryData ? true : false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, {resetForm}) => {
          const formData = new FormData();
          formData.append('description', values.description)
          formData.append('file', values.file);
          await updateOrCreateKnowledgeBase(entryData ? values : formData);
          resetForm();
        }}
        enableReinitialize
      >
        {({ setFieldValue, errors, touched, handleChange, isValid, dirty, values }) => (
          <Form id="deploy-form" className="form">

            <FormGroup>
              <Field
                id="entry-description"
                className="form-control"
                type="textarea"
                as="textarea"
                name="description"
                placeholder="Description"
                onChange={(e) => handleChange(e)}
              />
              {errors.description && touched.description ? (
                <div className="error">{errors.description}</div>
              ) : null}
            </FormGroup>

            {!entryData && (
              <FormGroup>
                <Dropzone
                  id="entry-file"
                  name="file"
                  onDrop={(file) => {
                    setFieldValue('file', file[0]);
                  }}
                  maxFiles={1}
                  maxSize={32 * 1024 * 1024}
                  // accept={['.csv', '.json', '.pdf', '.txt', '.docx' ]}
                  accept={['.pdf', '.txt', '.docx' ]}
                >
                  {({getRootProps, getInputProps, acceptedFiles}) => (
                    <div className="dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dropzone--select">
                          <div className="dropzone-icon"><i className="mdi mdi-cloud-upload" /></div>
                          <div className="dropzone-text">
                            <span className="dropzone-text-link">Click to upload file</span>
                            or drag and drop
                          </div>
                          <div className="dropzone-accept">File (max. 32MB)</div>
                        </div>
                      </div>
                      {acceptedFiles.length > 0 && (
                      <div className="dropzone-files">
                        {acceptedFiles.map((file) => (
                          <div key={file.path}>
                            {file.path} - {file.size} bytes
                          </div>
                        ))}
                      </div>
                    )}
                    </div>
                  )}
                </Dropzone>
                {errors.file && touched.file ? (
                  <div className="error">{errors.file}</div>
                ) : null}
              </FormGroup>
            )}

            <div className="form-actions">
              <Button
                type="submit"
                disabled={!isValid || !dirty}
                className="button button-default"
              >
                {entryData ? 'Update' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CreateNewEntryForm.propTypes = {
  closeModal: PropTypes.func,
  updateData: PropTypes.func,
}

export default CreateNewEntryForm;
