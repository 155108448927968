import React from 'react';
import Button from "../../Button/Button";

const AiKnowledgeBaseTableColumns = ({ onModalOpen }) => {
	const columns = [
		{
			Header: 'Name',
			accessor: 'fileName',
			Cell: ({value, row: { original }}) => (
				<Button
					onClick={() => onModalOpen(original.id, 'preview')}
					className="p-0"
				>
					{value}
				</Button>
			),
		},
		{
			Header: 'Description',
			accessor: 'description',
		},
		{
			Header: 'Created By',
			accessor: 'addedBy',
		},
    {
			Header: 'Created At',
			accessor: 'createdAt',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value}) => (
				<div className="actions" style={{display: 'inline-flex'}}>
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'delete')}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default AiKnowledgeBaseTableColumns;
