import React, {useEffect, useState} from 'react';
import {Menu, ProSidebar, SidebarContent, SidebarFooter} from 'react-pro-sidebar';
import {useDispatch, useSelector} from 'react-redux';
import CustomScroll from 'react-custom-scroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import {toggleRightSidebar} from '../../store/layout/actions';
import CollapseIcon from '../../images/sidebar-icon-colapse.svg';
import { renderSidebarItems } from './verticalLayoutHelper';

const Sidebar = ({
  toggleModal,
  trial,
  isTrialClosed,
  openOnboardingModal,
}) => {
  const { showRightSidebar: collapsed } = useSelector((state) => state.Layout);
  const {
    isWebTestEnabled,
    isActivePlan,
    companyDetails: { bluetooth_enabled: isBluetoothEnabled },
  } = useSelector((state) => state.Company);
  const { data: profileData } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState('overview');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [subMenus, setSubMenus] = useState({
    overview: ['/admin/overview', '/admin/broadband-insights'].includes(location.pathname),
    users: ['/admin/users', '/admin/invited-users'].includes(location.pathname),
    network: ['/admin/tests'].includes(location.pathname),
    settings: [
      '/admin/applications-settings',
      '/admin/announcements',
      '/admin/subscriptions',
      '/admin/sdk-credentials',
      '/admin/dashboard-settings',
      '/admin/extension-settings',
      '/admin/browser-test-settings',
      '/admin/issues-settings',
    ].includes(location.pathname),
    help: false,
    logs: ['/admin/logs'].includes(location.pathname),
    issues: ['/admin/issues'].includes(location.pathname),
    aiKnowledgeBase: ['/admin/ai-knowledge-base'].includes(location.pathname),
  });
  useEffect(() => {
    const cameFrom = window.Cookies.get('from');
    if (cameFrom) {
      window.Cookies.remove('from');
    }

    if (location.search?.includes('?invite-users')) {
      toggleModal('invite');
    }
    if (location.search?.includes('?invite-admin')) {
      toggleModal('admin');
    }
    if (location.search?.includes('?request-test')) {
      toggleModal('test');
    }
  }, []);
 
  const toggleSubmenu = (submenuName, status) => {
    setOpenMenu(status ? submenuName : null);
    setMenuIsOpen(status);
    setSubMenus(prevSubMenus => ({
      ...prevSubMenus,
      [submenuName]: status,
    }));
  };
  
  return (
    <div
      className={
        `sidebar${collapsed ? ' active' : ''}${trial && !isTrialClosed ? ' sidebar_top' : ''}`
      }
    >
      <button
        className="btn btn-link sidebar-btn"
        type="button"
        onClick={() => dispatch(toggleRightSidebar(!collapsed))}
      >
        <img src={CollapseIcon} alt="collapse-icon" width="20px" height="20px" />
      </button>
      <ProSidebar collapsed={!collapsed}>
        <SidebarContent>
          <CustomScroll heightRelativeToParent="100%">
            <Menu>
              {renderSidebarItems(
                subMenus,
                toggleSubmenu,
                profileData,
                isBluetoothEnabled,
                isWebTestEnabled,
                openOnboardingModal,
                isActivePlan
              )}
            </Menu>
          </CustomScroll>
        </SidebarContent>
        <SidebarFooter>
          <div className="sidebar-footer-wrapper">
            <button
              className="btn btn-link"
              type="button"
              onClick={() => dispatch(toggleRightSidebar(!collapsed))}
            >
              <span>
                <i className="mdi mdi-menu-open" />
                <i className="mdi mdi-menu" />
              </span>
            </button>
          </div>
        </SidebarFooter>

      </ProSidebar>
    </div>
  );
};

export default Sidebar;
